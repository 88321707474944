<template>
  <v-container :style="`${currentCustomReport && currentCustomReport.style && `max-width: ${currentCustomReport.style.width}px`}`">
    <report-list v-if="isBaseRoute" />

    <connected-app
      v-else-if="currentCustomReport"
      :custom-report="currentCustomReport"
    />

    <div v-else>
      <span>Loading ...</span>
    </div>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'CustomReports',
  components: {
    ConnectedApp: () => import('src/custom-reports/components/ConnectedApp'),
    ReportList: () => import('src/custom-reports/components/ReportList'),
  },
  computed: {
    ...mapToolsState({
      reportName: (state) => state.reportName,
    }),
    ...mapToolsGetters(['currentCustomReport']),
    isBaseRoute() {
      return ['custom-reports', 'reportlist'].includes(this.reportName)
    },

  },
}
</script>
